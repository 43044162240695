import * as React from 'react';
import { render } from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/global.css';
import { AuthProvider } from 'react-auth-kit';
import AppRoutes from './routes';
import ErrorBoundary from './components/ErrorBoundary';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { persistor, store } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from "react-ga4";

ReactGA.initialize("G-21JJRGX378");

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <ErrorBoundary>
          <div className="container-xxl py-3 d-flex flex-column vh-100">
            <Toaster>
              {(t) => (
                <ToastBar toast={t}>
                  {({ icon, message }) => (
                    <>
                      {icon}
                      {message}
                      {t.type !== 'loading' && (
                        <button className='btn btn-close' onClick={() => toast.dismiss(t.id)}>
                          <span className="visually-hidden">Dismiss message</span>
                        </button>
                      )}
                    </>
                  )}
                </ToastBar>
              )}
            </Toaster>
            <AuthProvider authType = {'localstorage'}
                          authName={'_auth'}>
              <AppRoutes />
            </AuthProvider>
          </div>
        </ErrorBoundary>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
