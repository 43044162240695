import React from 'react'
import { useSignOut } from 'react-auth-kit'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeUser } from '../features/user/userSlice';
import UserService from '../services/UserService';

const SignOutComponent = () => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const signOut = useSignOut();

    const drupalLogout = async () => {
      const userLogout = new UserService(user).logout();

      await userLogout
        .then((response) => {
          dispatch(removeUser());

          signOut();

          navigate("/");
        })
        .catch(error => {
          this.toastService.error('Unable to sign out: ' + error.message)
        });
    };

    return (
      <button className="btn btn-link text-danger text-decoration-none px-0" onClick={async () => {
        drupalLogout();
      }}>Logout</button>
    )
}

export default SignOutComponent;