// TODO: Make these as settings somehow
export const SYMBOL = 'TSLA';
export const RANGE = '3mo';
export const INTERVAL = '1h';

export const APP_NAME = 'STOCKYTO';

export const SUBSCRIPTION_PLANS = [
    {
        id: 1,
        machine_id: 'basic',
        name: 'Basic Plan',
        priceId: 'price_1MjTOtKvRQ0Iv5slDL4VjwEx'
    },
    {
        id: 2,
        machine_id: 'premium',
        name: 'Premium Plan',
        priceId: 'price_1Mnm6xKvRQ0Iv5sl1fmJ18iF'
    }
];

// export const DOMAIN = process.env.REACT_APP_STOCKS_API;
export const DRUPAL_DOMAIN = process.env.REACT_APP_DRUPAL_DOMAIN;
export const STRIPE_PK_TEST = process.env.REACT_APP_STRIPE_PK_TEST;

// export const STOCKS_BASE_URL = `https://${DOMAIN}:3005`;
export const YAHOO_STOCKS_BASE_URL = process.env.REACT_APP_STOCKS_API;