import { createSlice } from '@reduxjs/toolkit';

const initialState = []

export const stocksSlice = createSlice({
  name: 'stocks',
  initialState,
  reducers: {
    read: (state, action) => {
        return [...action.payload];
    },
    create: (state, action) => {
        return [...state, action.payload.stock];
    },
    update: (state, action) => {
        return state.map(stock => {
            if (stock.id === action.payload.stock.id) {
                return action.payload.stock;
            }

            return stock;
        });
    },
    remove: (state, action) => {
        return state.filter(stock => {
            return stock.id !== action.payload.id
        });
    }
  },
})

export const { read, create, update, remove } = stocksSlice.actions

export default stocksSlice.reducer