import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import WaitToLoad from "../components/ui/waitToLoad";

function PageWrapper() {
  return (
    <>
      <Header />
      <main>
        <Suspense fallback={<WaitToLoad />}>
          <Outlet />
        </Suspense>
      </main>
      <Footer />
    </>
  );
};

export default PageWrapper;
