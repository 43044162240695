import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from '../features/user/userSlice';
import stocksReducer from '../features/stocks/stocksSlice';
import strategiesReducer from '../features/strategies/strategiesSlice';
import subscriptionReducer from '../features/subscription/subscriptionSlice';

const reducers = combineReducers({
  user: userReducer,
  stocks: stocksReducer,
  strategies: strategiesReducer,
  subscription: subscriptionReducer
});

const persistConfig = {
  key: 'root-rtk',
  storage: storage,
  whitelist: ['user', 'stocks', 'strategies', 'subscription'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store);
