import React from 'react'
import { Link } from 'react-router-dom';
import packageJson from '../../package.json';

function Footer() {
    return (
        <footer className="footer mt-auto py-3 bg-light border-top">
            <div className="row">
                <div className="d-flex justify-content-between col-12 col-md">
                    <small className='text-muted'>v{ packageJson.version }</small>
                    <small className='btn btn-warning'><Link className="text-dark text-decoration-none" to={"/feedback"}>Feedback</Link></small>
                    <small className="text-muted">© {new Date().getFullYear()}</small>
                </div>
            </div>
        </footer>
    )
}

export default Footer;