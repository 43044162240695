import React from 'react'
import { useIsAuthenticated } from 'react-auth-kit';
import SignOutComponent from './SignOut';
import { Link } from 'react-router-dom';
import { APP_NAME } from '../settings';

function Header() {
    const isAuthenticated = useIsAuthenticated()

    return (
        <header>
            <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
                <Link className="d-flex align-items-center text-dark text-decoration-none" to={"/"}>
                    <span className="fs-4 fw-bold">{ APP_NAME }</span>
                </Link>

                <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
                    {
                        isAuthenticated() ?
                            <>
                                <Link className="me-3 py-2 text-dark text-decoration-none" to={"/dashboard"}>Dashboard</Link>
                                <Link className="me-3 py-2 text-dark text-decoration-none" to={"/copy"}>Copy</Link>
                                <Link className="me-3 py-2 text-dark text-decoration-none" to={"/settings"}>Settings</Link>
                                <SignOutComponent />
                            </>
                        :
                            <>
                                <Link className="me-3 py-2 text-dark text-decoration-none" to={"/register"}>Register</Link>
                                <Link className="py-2 text-dark text-decoration-none" to={"/login"}>Login</Link>
                            </>
                    }
                </nav>
            </div>
        </header>
    )
}

export default Header;