import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    personal: [],
    copied: []
}

function removeItemFiter(array, action) {
    return array.filter((item) => item.id !== action.payload.strategy.id)
}

export const strategiesSlice = createSlice({
  name: 'strategies',
  initialState,
  reducers: {
    read: (state, action) => {
        return {...state, personal: action.payload};
    },
    readCopied: (state, action) => {
        return {...state, copied: action.payload};
    },
    create: (state, action) => {
        return {...state, personal: Object.assign([...state.personal, action.payload.strategy])};
    },
    update: (state, action) => {
        let personalasd = state.personal.map(strategy => {
            if (strategy.id === action.payload.strategy.id) {
                return action.payload.strategy;
            }

            return strategy;
        });

        return {...state, personal: personalasd};
    },
    remove: (state, action) => {
        return state.personal.filter(strategy => {
            return strategy.id !== action.payload.id
        });
    },
    updateUserCopiedStrategies: (state, action) => {
        if (action.payload.action === 'add') {
            return {
                ...state,
                copied: Object.assign([...state.copied, action.payload.strategy])
            };
        } else if (action.payload.action === 'remove') {
            return {
                ...state,
                copied: removeItemFiter(state.copied, action)
            };
        }
    }
  },
})

export const { read, readCopied, create, update, remove, updateUserCopiedStrategies } = strategiesSlice.actions

export default strategiesSlice.reducer