import ToastService from "./ToastService";
import { DRUPAL_DOMAIN } from "../settings";
import axios from "axios";

export default class UserService {
    constructor(user) {
        this.user = user;
        this.auth = JSON.parse(localStorage.getItem('_auth_state'));
        this.toastService = new ToastService('User');
    }

    async logout() {
        const response = await this.toastService.promiseCustom(
            axios.post(
                `${DRUPAL_DOMAIN}/user/logout?_format=json`,
                {},
                {
                    headers: {
                        'Content-type': 'application/json',
                        'X-CSRF-Token': this.user.csrf_token
                    },
                    'params': {
                        'token': this.user.logout_token,
                    },
                    'withCredentials': true
                }
            )
            .then(function (response) {
                // console.log(response);
            })
            .catch(function (error) {
                console.log(error);

                throw new Error(error.message);
            }),
            'User sign out...',
            'See you soon!'
        )

        return response;
    }
}