import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uid: '',
  email: '',
  access_token: '',
  logout_token: '',
  csrf_token: '',
  stripe_customer_id: '',
  role: '',
  changePassword: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
        return {...action.payload};
    },

    setUserRole: (state, action) => {
        return {...state, role: action.payload.role };
    },

    removeUser: (state, action) => {
        return initialState;
    }
  },
})

export const { setUser, setUserRole, removeUser } = userSlice.actions

export default userSlice.reducer