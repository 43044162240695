import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentPlanId: null,
    buyPlan: {},
    subscription: {}
}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    getAll: (state, action) => {
      return {...state, subscription: action.payload.subscription};
    },

    setCurrentPlanId: (state, action) => {
      return {...state, currentPlanId: action.payload.currentPlanId};
    },

    buyPlan: (state, action) => {
      return {...state, buyPlan: action.payload.plan};
    },
  },
})

export const { getAll, setCurrentPlanId, buyPlan } = subscriptionSlice.actions

export default subscriptionSlice.reducer