import React, { lazy, useEffect } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { RequireAuth, withAuthUser } from 'react-auth-kit'
import PageWrapper from "./layouts/PageWrapper"
import ReactGA from "react-ga4";

const Home = lazy(() => import("./pages/home"))
const Login = lazy(() => import("./pages/login"))
const Register = lazy(() => import("./pages/register"))
const Dashboard = lazy(() => import("./pages/dashboard"))
const Copy = lazy(() => import("./pages/copy"))
const Settings = lazy(() => import("./pages/settings"))
const ForgotPassword = lazy(() => import("./pages/forgotPassword"))
const Subscription = lazy(() => import("./components/subscription/Subscription"))
const StockList = lazy(() => import("./components/StockList"))
const StrategyList = lazy(() => import("./components/StrategyList"))
const ProfileForm = lazy(() => import("./components/forms/ProfileForm"))
const PasswordForm = lazy(() => import("./components/forms/PasswordForm"))
const Feedback = lazy(() => import("./pages/feedback"))

function AppRoutes(props) {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<PageWrapper />}>
                    {/* Not protected routes */}
                    <Route path={'/'} element={<Home />}/>
                    <Route path={'/login'} element={<Login />}/>
                    <Route path={'/register'} element={<Register />}/>
                    <Route path={'/forgot-password'} element={<ForgotPassword />}/>

                    {
                        props.authState?.changePassword
                        ?
                        <>
                            <Route path={'/settings/password'} element={
                                <RequireAuth loginPath={'/login'}>
                                    <PasswordForm />
                                </RequireAuth>
                            }/>

                            {/* All other matched routes go to settings to change the password */}
                            <Route path={'*'} element={
                                <Navigate to={"/settings/password"} />
                            }/>
                        </>
                        :
                        <>
                            {/* If Everything is OK with the password user can access these Protected routes */}
                            <Route path={'/dashboard'} element={
                                <RequireAuth loginPath={'/login'}>
                                    <Dashboard />
                                </RequireAuth>
                            }/>

                            <Route path={'/copy'} element={
                                <RequireAuth loginPath={'/login'}>
                                    <Copy />
                                </RequireAuth>
                            }/>

                            <Route path={'/feedback'} element={
                                <RequireAuth loginPath={'/login'}>
                                    <Feedback />
                                </RequireAuth>
                            }/>

                            <Route path={'/settings'} element={
                                <RequireAuth loginPath={'/login'}>
                                    <Settings />
                                </RequireAuth>
                            }>
                                <Route index element={
                                    <RequireAuth loginPath={'/login'}>
                                        <ProfileForm />
                                    </RequireAuth>
                                } />

                                <Route path="profile" element={
                                    <RequireAuth loginPath={'/login'}>
                                        <ProfileForm />
                                    </RequireAuth>
                                } />

                                <Route path="subscription" element={
                                    <RequireAuth loginPath={'/login'}>
                                        <Subscription />
                                    </RequireAuth>
                                } />

                                <Route path="stocks" element={
                                    <RequireAuth loginPath={'/login'}>
                                        <StockList />
                                    </RequireAuth>
                                } />

                                <Route path="strategies" element={
                                    <RequireAuth loginPath={'/login'}>
                                        <StrategyList />
                                    </RequireAuth>
                                } />

                                <Route path="password" element={
                                    <RequireAuth loginPath={'/login'}>
                                        <PasswordForm />
                                    </RequireAuth>
                                } />
                            </Route>

                            {/* All other matched routes */}
                            <Route path={'*'} element={
                                <Navigate to={"/"} />
                            }/>
                        </>
                    }
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default withAuthUser(AppRoutes);