import React, { Component } from "react";
import { Button } from "react-bootstrap";

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, error: {} };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    handleReloadClick(event) {
        event.preventDefault();

        window.location.reload();
    }

    render() {
        const { hasError, error } = this.state;

        if (hasError) {
            return <div className="d-flex flex-column justify-content-center align-items-center vh-100">
                <h4>Something went wrong.</h4>
                <p>{error.message}</p>
                <p>Please refresh!</p>
                <Button variant="primary" size="lg" onClick={this.handleReloadClick}>
                    Reload
                </Button>
            </div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;