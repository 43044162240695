import toast from "react-hot-toast";

export const EntityTypePluralNames = {
    Stocks: 'Stocks',
    Strategies: 'Strategies',
    Subscriptions: 'Subscriptions'
}

export default class ToastService {
    constructor(type) {
        this.type = type;

        this.options = {
            duration: 3000,
            position: 'top-right',
        }
    }

    error(message) {
        return toast.error(
            message,
            this.options
        );
    }

    promise(promise) {
        return this.promiseCustom(
            promise,
            'Loading ' + this.type + '...',
            this.type + ' are loaded!'
        );
    }

    promiseCreateOrUpdate(promise, entity) {
        let loadingType = entity.id ? 'Updating' : 'Creating';
        let successType = entity.id ? 'updated' : 'created';

        return this.promiseCustom(
            promise,
            loadingType + ' ' + entity.name + '...',
            entity.name + ' is ' + successType
        );
    }

    promiseDelete(promise, entity) {
        return this.promiseCustom(
            promise,
            'Deleting ' + entity.name + '...',
            entity.name + ' is deleted!'
        );
    }

    promiseCustom(promise, loading, success) {
        return toast.promise(
            promise,
            {
                loading: () => {
                    return loading;
                },
                success: success,
                error: (error) => {
                    return error.message;
                }
            },
            this.options
        );
    }
}