import { Spinner } from 'react-bootstrap';

function WaitToLoad(props) {
    const {waitIf, children} = props;

    return (
        <>
            {waitIf
                ?
                    children
                :
                    <div className='loader' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1}}>
                        <Spinner style={{width: 100, height: 100}} animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
            }
        </>
    )
}

export default WaitToLoad;
